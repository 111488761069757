let Environment = {
  disperser: "0xE7ac4Cc1da49A78875a30215BB43d3638ce316C8",
  base_url: "https://api.quecko.com/v1",
  // metaSkeletonContract: "0xf41CCd39c65438Bb000d1e67EbDBE25Da14A4252",

  // Osama bhai contract 
  metaSkeletonContract: "0x1616EbdD7D3a0eacfd34492099C144220ba75223"

};

export default Environment;