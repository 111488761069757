const CartData=[
    {
        id:1,
        src:'../images/tshirt.jpg',
        qty:1,
        productname:'T SHIRT',
        detail:'Sed ut perspiciatis unde omnis iste natus voluptatem accusantium doloremque totam rem aperiam sint occaecat cupidatat non proident, sunt in culpa qui officia.',
        price:'299.00',
        size: ''
    },
    {
        id:2,
        src:'../images/tshirt.jpg',
        qty:1,
        productname:'HOODIE',
        detail:'Sed ut perspiciatis unde omnis iste natus voluptatem accusantium doloremque totam rem aperiam sint occaecat cupidatat non proident, sunt in culpa qui officia.',
        price:'340.00',
        size: ''
    },
    {
        id:3,
        src:'../images/tshirt.jpg',
        qty:1,
        productname:'CAP',
        detail:'Sed ut perspiciatis unde omnis iste natus voluptatem accusantium doloremque totam rem aperiam sint occaecat cupidatat non proident, sunt in culpa qui officia.',
        price:'499.00',
        size: ''
    },
];
export default CartData;