/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Minting from "../../hooks/Minting";
import "./mint.scss";
import { useWeb3React } from "@web3-react/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckMint from "../../hooks/minted";
// import Loader from "../../hooks/loader";
import Footer from "../landing/footer/Footer";
import CheckFees from "../../hooks/getFees";
import useAuth from "../../hooks/useAuth";
import Navbar from "../landing/header/Navbar";
import arr from "../../hooks/wallet1";
import CheckPurchase from "../../hooks/maxPurchase";
import CheckPublicSale from "../../hooks/publicSale";

const Mint = () => {
  const { account } = useWeb3React();
  const [minted, setMinted] = useState(0);
  const [toMint, setToMint] = useState(1);
  const [publicSale, setPublicSale] = useState(false);
  const [fees, setFees] = useState(0);
  const [mainLoader, setMainLoader] = useState(false);
  const [maxMint, setMaxMint] = useState(0);
  const { userMinting } = Minting();
  const { minte } = CheckMint();
  const { Fees } = CheckFees();
  const { Purchase } = CheckPurchase();
  const { Public } = CheckPublicSale();

  const Subtract = () => {
    if (toMint > 1) {
      setToMint(toMint - 1);
    }
  };

  useEffect(async () => {
    const res = await Public();
    setPublicSale(res);
  }, []);

  // useEffect(async () => {
  //   const res = await WhiteOne();
  //   if (res === false) {
  //     const res0 = await WhiteTwo();
  //     if (res0 === false) {
  //       setWhitePhase(2);
  //     } else {
  //       setWhitePhase(0);
  //     }
  //   } else {
  //     setWhitePhase(1);
  //   }
  // }, []);

  const whitelisted = arr.find((e) => e === account);

  const Add = () => {
    if (toMint < maxMint) {
      setToMint(toMint + 1);
    }
  };

  useEffect(async () => {
    const res0 = await Fees();
    await setFees(parseInt(res0) / 1000000000000000000);
  }, []);

  useEffect(async () => {
    const res0 = await Purchase();
    await setMaxMint(parseInt(res0));
  }, []);

  const startMinting = async () => {

    if (!account) {
      toast.error("Please connect wallet");
      return;
    }

    // if (!publicSale) {
    //   toast.error("Sale Not active");
    //   return;
    // }

    if (!whitelisted) {
      toast.error("Account not whitelisted");
      return;
    }

    if (toMint <= 0) {
      toast.error("Zero Mint not Allowed");
      return;
    }


    setMainLoader(true);

    try {
      await userMinting(toMint);
      toast.success("Minted Successfully!");
    } catch (err) {
      toast.error(err.message);
    }

    setMainLoader(false);
  }


  useEffect(async () => {
    const res = await minte();
    await setMinted(res);
  }, []);
  useEffect(() => {
    if (account) {
      window.$("#exampleModalmerch").modal("hide");
    } else {
      window.$("#exampleModalmerch").modal("show");
    }
  }, [account]);

  return (
    <>
      <ToastContainer />
      {/* {mainLoader && <Loader />} */}
      <div className="brdr"></div>
      <section className="main-banner-mint" id="aboutus">
        <Navbar />
        <div className="container p-0">
          <div className="row ptb">
            <div className="col-sm-12">
              <div className="inner-img text-center">
                <h2 className="white ptb20">
                Reclamar tu
                  <span className="rebort text-uppercase"> NFT </span>
                </h2>
                {/* <p className="grey pt20 ">
                  Sed ut perspiciatis unde omnis iste natus voluptatem{" "}
                </p>
                <p className="grey">
                  accusantium doloremque totam rem aperiam.
                </p> */}
                {/* <p className="grey">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium <br /> doloremque laudantium, totam rem aperiam eaque ipsa.</p> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 order-lg-0 order-1">
              <div className=" container p-0 card fullcard ptb20">
                <h2 className=" white text-center mt-4 ">Reclamar tu NFT</h2>
                <p className="grey text-center pt-1"> {minted} NFTs Reclamados</p>
                <ul className="list-inline text-center ptb20">
                  <li className="list-inline-item">
                    <div className="inner-side w-25">
                      <button
                        onClick={() => Subtract()}
                        className="for-btn "
                        type="button"
                      >
                        -
                      </button>
                    </div>
                  </li>
                  <li className="list-inline-item">
                    <div className="inner-side">
                      <input
                        type="text"
                        disabled
                        value={toMint}
                        className="form-control placeholder"
                        placeholder="1"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                    </div>
                  </li>
                  <li className="list-inline-item">
                    <div className="inner-side">
                      <button
                        onClick={() => Add()}
                        className="for-btn"
                        type="button"
                      >
                        +
                      </button>
                    </div>
                  </li>
                </ul>
                <div className="gray ptb10 text-center">
                  <p className=" grey text-center ptb20">
                    {" "}
                    <span className="white">Total:</span>{" "}
                    {(fees * toMint).toFixed(2)} ETH
                  </p>
                </div>
                <ul className="list-inline ptb20 text-center ">
                  <li className="list-inline-item">
                    <button
                      // disabled={whitelisted !== true}
                      className="btn-common w-100 px-5"
                      onClick={() => startMinting()}
                    >
                      Reclamar{" "}
                    </button>
                  </li>
                </ul>
                {publicSale === false && (
                  <ul className="list-inline text-center ">
                    <li className="list-inline-item">
                      {whitelisted ? (
                        <button className="btn-common bg-success px-5">
                          <img
                            src="\check.png"
                            alt=""
                            className="img-fluid mr-2"
                            style={{ width: "20px" }}
                          />{" "}
                          en la lista blanca
                        </button>
                      ) : (
                        <button className="btn-common bg-danger ">
                          <img
                            src="\close.png"
                            alt=""
                            className="img-fluid mr-2"
                            style={{ width: "15px" }}
                          />{" "}
                          No incluído en lista blancas
                        </button>
                      )}
                    </li>
                  </ul>
                )}
                <div className="brdr mt-5"></div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="inner-addresses">
                      <div className="gray  ptb20">
                        <p className=" grey "> NFT Contract Address</p>
                        <a
                          href="https://etherscan.io/address/0x1616EbdD7D3a0eacfd34492099C144220ba75223"
                          className=" white d-flex w-100 text-truncate"
                        >
                          <img className="mr-2 img-fluid" src="path1.png" />{" "}
                          0x1616EbdD7D3a0eacfd34492099C144220ba75223
                        </a>
                      </div>
                      <div className="brdr"></div>
                      <div className="gray ptb20">
                        <p className=" grey "> Your Wallet Address</p>
                        <a className="white d-flex w-100 text-truncate align-items-center">
                          <img src="metamask1.png" className="mr-2 img-fuild" />{" "}
                          {account}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 my-auto d-lg-block d-none">
              <div className="inner-content text-center">
                <div className="inner-img">
                  <img
                    src="preview.gif"
                    className="img-fluid ml-md-5 roboImg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Mint;
