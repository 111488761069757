import React, { useEffect, useState } from 'react'
import Footer from '../landing/footer/Footer'
import Navbar from '../landing/header/Navbar'
import './nftperks.scss'
import qs from "qs";
import { useWeb3React } from "@web3-react/core";
import { ToastContainer, toast } from "react-toastify";
import { API_URL } from '../../utils/ApiURL';
import axios from "axios";

function NftPerks() {
    const { account } = useWeb3React();
    const [rend, setRend] = useState(false);
    const [nftID, setNftID] = useState([]);
    const [inputData, setInputData] = useState({
        fullName: "",
        email: "",
        phone: "",
        nftId: "",
        shippingAddress: "",
        guest1: "",
        guest2: "",
        guest3: "",
        guest4: "",
        guest5: "",
        guest6: "",
        guest7: "",
        guest8: "",
        guest9: "",
        guest10: "",
        guest1Error: "",
        guest2Error: "",
        guest3Error: "",
        guest4Error: "",
        guest5Error: "",
        guest6Error: "",
        guest7Error: "",
        guest8Error: "",
        guest9Error: "",
        guest10Error: "",
        fullNameError: "",
        emailError: "",
        nftIdError: "",
        shippingAddressError: ""
    });


    const data = async () => {
        // const account1 = "0xD01eF9E9B79C785348263D6529B9821395413437";
        // const testAccount = "0xf02a7fec86787fd29427cad8581d400d0bb660f1";

        const contract_address = "0xbb04aAFd02b6CAfB86D163861899aEA34EBdCB46";
        if (account) {
            var config = {
                method: "get",
                url: `https://deep-index.moralis.io/api/v2/${account}/nft/${contract_address}?chain=eth&format=decimal`,
                headers: {
                    "x-api-key":
                        "E9LIXe1rcylRjWsSw8DIXCQCFBA18qCxTrzRXR7cpwA9GIgI91D6sxz7YZhAFIy4",
                },
            };
            let driven = await axios(config);
            if (driven.data.result && Array.isArray(driven.data.result) && driven.data.result.length > 0) {
                driven = driven.data.result
            } else {
                driven = []

            }

            config = {
                method: "get",
                url: `https://deep-index.moralis.io/api/v2/${account}/nft/0x254E99D5c6CB01410f6B7bb87c0b1321B7a21bD0?chain=eth&format=decimal`,
                headers: {
                    "x-api-key":
                        "E9LIXe1rcylRjWsSw8DIXCQCFBA18qCxTrzRXR7cpwA9GIgI91D6sxz7YZhAFIy4",
                },
            };


            let silver = await axios(config)
            if (silver.data.result && Array.isArray(silver.data.result) && silver.data.result.length > 0) {
                silver = silver.data.result
            } else {
                silver = []
            }


            config = {
                method: "get",
                url: `https://deep-index.moralis.io/api/v2/${account}/nft/0x8D7F3cA1190Bed80ADe50D91779D623a850Eb416?chain=eth&format=decimal`,
                headers: {
                    "x-api-key":
                        "E9LIXe1rcylRjWsSw8DIXCQCFBA18qCxTrzRXR7cpwA9GIgI91D6sxz7YZhAFIy4",
                },
            };

            let gold = await axios(config)
            if (gold.data.result && Array.isArray(gold.data.result) && gold.data.result.length > 0) {
                gold = gold.data.result
            } else {
                gold = []
            }

            config = {
                method: "get",
                url: `https://deep-index.moralis.io/api/v2/${account}/nft/0xA7A0759f6cA0AbD89448bBFe8A6771a2c8fbdcdb?chain=eth&format=decimal`,
                headers: {
                    "x-api-key":
                        "E9LIXe1rcylRjWsSw8DIXCQCFBA18qCxTrzRXR7cpwA9GIgI91D6sxz7YZhAFIy4",
                },
            };
            let black = await axios(config)
            if (black.data.result && Array.isArray(black.data.result) && black.data.result.length > 0) {
                black = black.data.result
            } else {
                black = []
            }

            setNftID([...driven, ...silver, ...black, ...gold]);
        }

    };


    const handleInputChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        setInputData({ ...inputData, [inputName]: inputValue });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let formValid = true;
        var regEmail = /\S+@\S+\.\S+/;

        let dumObj = inputData;
        if (dumObj.fullName === "" && dumObj.email === "") {
            dumObj.fullNameError = "Please enter your full name";
            dumObj.emailError = "Please enter your email";
            formValid = false;
        } else if (dumObj.fullName === "") {
            dumObj.fullNameError = "Please Enter your Full Name";
            formValid = false;
        } else if (dumObj.email === "") {
            dumObj.emailError = "Please enter your Email";

            dumObj.fullNameError = "";
            dumObj.shippingAddressError = "";

            formValid = false;
        } else if (!regEmail.test(dumObj.email)) {
            dumObj.emailError = "Please enter valid Email";
            dumObj.fullNameError = "";
            formValid = false;
        } else if (dumObj.nftId === "") {
            dumObj.nftIdError = "Please Select NFT ID";
            dumObj.shippingAddressError = "";
            dumObj.emailError = "";
            dumObj.fullNameError = "";
            formValid = false;
        } else if (dumObj.shippingAddress === "") {
            dumObj.shippingAddressError = "Please Enter Address";
            dumObj.emailError = "";
            dumObj.fullNameError = "";
            formValid = false;
        }
        setInputData(dumObj);
        setRend(!rend);
        if (formValid) {
            setInputData({
                ...inputData,
                fullNameError: "",
                emailError: "",
            });
            var nft = true;

            for (let t of nftID) {
                if (t.token_id >= 1334 && t.token_id <= 1403) {
                    toast.error("This is Fake NFT ID");
                    nft = false;
                }
            }
            if (nft == true) {
                bookTicket();
            } else {
                toast.error("Please try later");
            }
        }
    };

    const handleSubmitWolf = async (e) => {
        e.preventDefault();
        let formValid = true;
        var regEmail = /\S+@\S+\.\S+/;

        let dumObj = inputData;
        if (dumObj.fullName === "" && dumObj.email === "") {
            dumObj.fullNameError = "Please enter your full name";
            dumObj.emailError = "Please enter your email";
            formValid = false;
        } else if (dumObj.fullName === "") {
            dumObj.fullNameError = "Please Enter your Full Name";
            formValid = false;
        } else if (dumObj.email === "") {
            dumObj.emailError = "Please enter your Email";

            dumObj.fullNameError = "";
            dumObj.shippingAddressError = "";

            formValid = false;
        } else if (!regEmail.test(dumObj.email)) {
            dumObj.emailError = "Please enter valid Email";
            dumObj.fullNameError = "";
            formValid = false;
        } else if (dumObj.nftId === "") {
            dumObj.nftIdError = "Please Select NFT ID";
            dumObj.shippingAddressError = "";
            dumObj.emailError = "";
            dumObj.fullNameError = "";
            formValid = false;
        } else if (dumObj.shippingAddress === "") {
            dumObj.shippingAddressError = "Please Enter Address";
            dumObj.emailError = "";
            dumObj.fullNameError = "";
            formValid = false;
        }
        setInputData(dumObj);
        setRend(!rend);
        if (formValid) {
            let { data } = await axios.get(`https://ipfs.infura.io/ipfs/QmVeg8tb4tLgz3gbbLgNzJ3CScyN7VexJ4aXrRt7VeHRNw/${dumObj.nftId}`);
            console.log('data::::', data)

            if (data.attributes && data.attributes[0]) {
                if (data.attributes[0].value !== 'Ultra Rare') {
                    alert('No Ultra Rare NFT Found in your wallet. Please select another wallet')
                    return;
                }
            }

            setInputData({
                ...inputData,
                fullNameError: "",
                emailError: "",
            });
            var nft = true;

            for (let t of nftID) {
                if (t.token_id >= 1334 && t.token_id <= 1403) {
                    toast.error("This is Fake NFT ID");
                    nft = false;
                }
            }
            if (nft == true) {
                bookTicketWolf();
            } else {
                toast.error("Please try later");
            }
        }
    };

    const handleSubmitLion = async (e) => {
        e.preventDefault();
        let formValid = true;
        var regEmail = /\S+@\S+\.\S+/;

        let dumObj = inputData;
        if (dumObj.fullName === "" && dumObj.email === "") {
            dumObj.fullNameError = "Please enter your full name";
            dumObj.emailError = "Please enter your email";
            formValid = false;
        } else if (dumObj.fullName === "") {
            dumObj.fullNameError = "Please Enter your Full Name";
            formValid = false;
        } else if (dumObj.email === "") {
            dumObj.emailError = "Please enter your Email";

            dumObj.fullNameError = "";
            dumObj.shippingAddressError = "";

            formValid = false;
        } else if (!regEmail.test(dumObj.email)) {
            dumObj.emailError = "Please enter valid Email";
            dumObj.fullNameError = "";
            formValid = false;
        } else if (dumObj.nftId === "") {
            dumObj.nftIdError = "Please Select NFT ID";
            dumObj.shippingAddressError = "";
            dumObj.emailError = "";
            dumObj.fullNameError = "";
            formValid = false;
        } else if (dumObj.shippingAddress === "") {
            dumObj.shippingAddressError = "Please Enter Address";
            dumObj.emailError = "";
            dumObj.fullNameError = "";
            formValid = false;
        }
        setInputData(dumObj);
        setRend(!rend);
        if (formValid) {
            let { data } = await axios.get(`https://ipfs.infura.io/ipfs/QmVeg8tb4tLgz3gbbLgNzJ3CScyN7VexJ4aXrRt7VeHRNw/${dumObj.nftId}`);
            console.log('data::::', data)
            if (data.attributes && data.attributes[0]) {
                if (data.attributes[0].value !== 'Rare') {
                    alert('No Rare NFT Found in your wallet. Please select another wallet')
                    return;
                }
            }
            setInputData({
                ...inputData,
                fullNameError: "",
                emailError: "",
            });
            var nft = true;

            for (let t of nftID) {
                if (t.token_id >= 1334 && t.token_id <= 1403) {
                    toast.error("This is Fake NFT ID");
                    nft = false;
                }
            }
            if (nft == true) {
                bookTicketLion();
            } else {
                toast.error("Please try later");
            }
        }
    };

    const handleSubmitEarly = async (e) => {
        e.preventDefault();
        let formValid = true;
        var regEmail = /\S+@\S+\.\S+/;

        let dumObj = inputData;
        if (dumObj.fullName === "" && dumObj.email === "") {
            dumObj.fullNameError = "Please enter your full name";
            dumObj.emailError = "Please enter your email";
            formValid = false;
        } else if (dumObj.fullName === "") {
            dumObj.fullNameError = "Please Enter your Full Name";
            formValid = false;
        } else if (dumObj.email === "") {
            dumObj.emailError = "Please enter your Email";

            dumObj.fullNameError = "";
            dumObj.shippingAddressError = "";

            formValid = false;
        } else if (!regEmail.test(dumObj.email)) {
            dumObj.emailError = "Please enter valid Email";
            dumObj.fullNameError = "";
            formValid = false;
        } else if (dumObj.nftId === "") {
            dumObj.nftIdError = "Please Select NFT ID";
            dumObj.shippingAddressError = "";
            dumObj.emailError = "";
            dumObj.fullNameError = "";
            formValid = false;
        }
        else if (dumObj.guest1 != "") {
            if (!regEmail.test(dumObj.guest1)) {
                dumObj.guest1Error = "Please enter valid Guest 1 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest2 != "") {
            if (!regEmail.test(dumObj.guest2)) {
                dumObj.guest2Error = "Please enter valid Guest 2 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest3 != "") {
            if (!regEmail.test(dumObj.guest3)) {
                dumObj.guest3Error = "Please enter valid Guest 3 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest4 != "") {
            if (!regEmail.test(dumObj.guest4)) {
                dumObj.guest4Error = "Please enter valid Guest 4 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest5 != "") {
            if (!regEmail.test(dumObj.guest5)) {
                dumObj.guest5Error = "Please enter valid Guest 5 Email";
                formValid = false;
            }
        }
        setInputData(dumObj);
        setRend(!rend);
        if (formValid) {
            let { data } = await axios.get(`https://ipfs.infura.io/ipfs/QmVeg8tb4tLgz3gbbLgNzJ3CScyN7VexJ4aXrRt7VeHRNw/${dumObj.nftId}`);
            if (data.attributes && data.attributes[0]) {
                if (data.attributes[0].value !== 'Ultra rare' && data.attributes[0].value !== 'Legendary') {
                    alert('Specific NFT Not Found in your wallet. Please select another wallet')
                    return;
                }
            }
            setInputData({
                ...inputData,
                fullNameError: "",
                emailError: "",
                guest1Error: "",
                guest2Error: "",
                guest3Error: "",
                guest4Error: "",
                guest5Error: "",
            });
            var nft = true;

            for (let t of nftID) {
                if (t.token_id >= 1334 && t.token_id <= 1403) {
                    toast.error("This is Fake NFT ID");
                    nft = false;
                }
            }
            if (nft == true) {
                bookTicketEarly();
            } else {
                toast.error("Please try later");
            }
        }
    };

    const handleSubmitLaunch = async (e) => {
        e.preventDefault();
        let formValid = true;
        var regEmail = /\S+@\S+\.\S+/;

        let dumObj = inputData;
        if (dumObj.fullName === "" && dumObj.email === "") {
            dumObj.fullNameError = "Please enter your full name";
            dumObj.emailError = "Please enter your email";
            formValid = false;
        } else if (dumObj.fullName === "") {
            dumObj.fullNameError = "Please Enter your Full Name";
            formValid = false;
        } else if (dumObj.email === "") {
            dumObj.emailError = "Please enter your Email";

            dumObj.fullNameError = "";
            dumObj.shippingAddressError = "";

            formValid = false;
        } else if (!regEmail.test(dumObj.email)) {
            dumObj.emailError = "Please enter valid Email";
            dumObj.fullNameError = "";
            formValid = false;
        }
        else if (dumObj.nftId === "") {
            dumObj.nftIdError = "Please Select NFT ID";
            dumObj.shippingAddressError = "";
            dumObj.emailError = "";
            dumObj.fullNameError = "";
            formValid = false;
        }
        else if (dumObj.guest1 != "") {
            if (!regEmail.test(dumObj.guest1)) {
                dumObj.guest1Error = "Please enter valid Guest 1 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest2 != "") {
            if (!regEmail.test(dumObj.guest2)) {
                dumObj.guest2Error = "Please enter valid Guest 2 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest3 != "") {
            if (!regEmail.test(dumObj.guest3)) {
                dumObj.guest3Error = "Please enter valid Guest 3 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest4 != "") {
            if (!regEmail.test(dumObj.guest4)) {
                dumObj.guest4Error = "Please enter valid Guest 4 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest5 != "") {
            if (!regEmail.test(dumObj.guest5)) {
                dumObj.guest5Error = "Please enter valid Guest 5 Email";
                formValid = false;
            }
        }
        setInputData(dumObj);
        setRend(!rend);
        if (formValid) {
            // let { data } = await axios.get(`https://ipfs.infura.io/ipfs/QmVeg8tb4tLgz3gbbLgNzJ3CScyN7VexJ4aXrRt7VeHRNw/${dumObj.nftId}`);
            // if (data.attributes && data.attributes[0]) {
            //     if (data.attributes[0].value !== 'Uncommon' && data.attributes[0].value !== 'Ultra rare' && data.attributes[0].value !== 'Rare' && data.attributes[0].value !== 'Legendary') {
            //         alert('Specific NFT Not Found in your wallet. Please select another wallet')
            //         return;
            //     }
            // }
            setInputData({
                ...inputData,
                fullNameError: "",
                emailError: "",
                guest1Error: "",
                guest2Error: "",
                guest3Error: "",
                guest4Error: "",
                guest5Error: "",
            });
            var nft = true;

            for (let t of nftID) {
                if (t.token_id >= 1334 && t.token_id <= 1403) {
                    toast.error("This is Fake NFT ID");
                    nft = false;
                }
            }
            if (nft == true) {
                bookTicketLaunch();
            } else {
                toast.error("Please try later");
            }
        }
    };

    const handleSubmitDay = async (e) => {
        e.preventDefault();
        let formValid = true;
        var regEmail = /\S+@\S+\.\S+/;

        let dumObj = inputData;
        if (dumObj.fullName === "" && dumObj.email === "") {
            dumObj.fullNameError = "Please enter your full name";
            dumObj.emailError = "Please enter your email";
            formValid = false;
        } else if (dumObj.fullName === "") {
            dumObj.fullNameError = "Please Enter your Full Name";
            formValid = false;
        } else if (dumObj.email === "") {
            dumObj.emailError = "Please enter your Email";

            dumObj.fullNameError = "";
            dumObj.shippingAddressError = "";

            formValid = false;
        } else if (!regEmail.test(dumObj.email)) {
            dumObj.emailError = "Please enter valid Email";
            dumObj.fullNameError = "";
            formValid = false;
        } else if (dumObj.nftId === "") {
            dumObj.nftIdError = "Please Select NFT ID";
            dumObj.shippingAddressError = "";
            dumObj.emailError = "";
            dumObj.fullNameError = "";
            formValid = false;
        }
        else if (dumObj.guest1 != "") {
            if (!regEmail.test(dumObj.guest1)) {
                dumObj.guest1Error = "Please enter valid Guest 1 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest2 != "") {
            if (!regEmail.test(dumObj.guest2)) {
                dumObj.guest2Error = "Please enter valid Guest 2 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest3 != "") {
            if (!regEmail.test(dumObj.guest3)) {
                dumObj.guest3Error = "Please enter valid Guest 3 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest4 != "") {
            if (!regEmail.test(dumObj.guest4)) {
                dumObj.guest4Error = "Please enter valid Guest 4 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest5 != "") {
            if (!regEmail.test(dumObj.guest5)) {
                dumObj.guest5Error = "Please enter valid Guest 5 Email";
                formValid = false;
            }
        }
        setInputData(dumObj);
        setRend(!rend);
        if (formValid) {
            let { data } = await axios.get(`https://ipfs.infura.io/ipfs/QmVeg8tb4tLgz3gbbLgNzJ3CScyN7VexJ4aXrRt7VeHRNw/${dumObj.nftId}`);
            console.log('data::::', data)
            // if (data.attributes && data.attributes[0]) {
            //     if (data.attributes[0].value !== 'Rare') {
            //         alert('No Rare NFT Found in your wallet. Please select another wallet')
            //         return;
            //     }
            // }
            setInputData({
                ...inputData,
                fullNameError: "",
                emailError: "",
                guest1Error: "",
                guest2Error: "",
                guest3Error: "",
                guest4Error: "",
                guest5Error: "",
            });
            var nft = true;

            for (let t of nftID) {
                if (t.token_id >= 1334 && t.token_id <= 1403) {
                    toast.error("This is Fake NFT ID");
                    nft = false;
                }
            }
            if (nft == true) {
                bookTicketDay();
            } else {
                toast.error("Please try later");
            }
        }
    };

    const handleSubmitCryptoFest = async (e) => {
        e.preventDefault();
        let formValid = true;
        var regEmail = /\S+@\S+\.\S+/;

        let dumObj = inputData;

        if (dumObj.fullName === "" && dumObj.email === "") {
            dumObj.fullNameError = "Please enter your full name";
            dumObj.emailError = "Please enter your email";
            formValid = false;
        } else if (dumObj.fullName === "") {
            dumObj.fullNameError = "Please Enter your Full Name";
            formValid = false;
        } else if (dumObj.email === "") {
            dumObj.emailError = "Please enter your Email";

            dumObj.fullNameError = "";
            dumObj.shippingAddressError = "";

            formValid = false;
        } else if (!regEmail.test(dumObj.email)) {
            dumObj.emailError = "Please enter valid Email";
            dumObj.fullNameError = "";
            formValid = false;
        } else if (dumObj.nftId === "") {
            dumObj.nftIdError = "Please Select NFT ID";
            dumObj.shippingAddressError = "";
            dumObj.emailError = "";
            dumObj.fullNameError = "";
            formValid = false;
        }
        else if (dumObj.guest1 != "") {
            if (!regEmail.test(dumObj.guest1)) {
                dumObj.guest1Error = "Please enter valid Guest 1 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest2 != "") {
            if (!regEmail.test(dumObj.guest2)) {
                dumObj.guest2Error = "Please enter valid Guest 2 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest3 != "") {
            if (!regEmail.test(dumObj.guest3)) {
                dumObj.guest3Error = "Please enter valid Guest 3 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest4 != "") {
            if (!regEmail.test(dumObj.guest4)) {
                dumObj.guest4Error = "Please enter valid Guest 4 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest5 != "") {
            if (!regEmail.test(dumObj.guest5)) {
                dumObj.guest5Error = "Please enter valid Guest 5 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest6 != "") {
            if (!regEmail.test(dumObj.guest6)) {
                dumObj.guest6Error = "Please enter valid Guest 6 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest7 != "") {
            if (!regEmail.test(dumObj.guest7)) {
                dumObj.guest7Error = "Please enter valid Guest 7 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest8 != "") {
            if (!regEmail.test(dumObj.guest8)) {
                dumObj.guest8Error = "Please enter valid Guest 8 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest9 != "") {
            if (!regEmail.test(dumObj.guest9)) {
                dumObj.guest9Error = "Please enter valid Guest 9 Email";
                formValid = false;
            }
        }
        else if (dumObj.guest10 != "") {
            if (!regEmail.test(dumObj.guest10)) {
                dumObj.guest10Error = "Please enter valid Guest 10 Email";
                formValid = false;
            }
        }
        setInputData(dumObj);
        setRend(!rend);
        if (formValid) {
            setInputData({
                ...inputData,
                fullNameError: "",
                emailError: "",
                guest1Error: "",
                guest2Error: "",
                guest3Error: "",
                guest4Error: "",
                guest5Error: "",
                guest6Error: "",
                guest7Error: "",
                guest8Error: "",
                guest9Error: "",
                guest10Error: "",
            });
            var nft = true;

            for (let t of nftID) {
                if (t.token_id >= 1334 && t.token_id <= 1403) {
                    toast.error("This is Fake NFT ID");
                    nft = false;
                }
            }
            if (nft == true) {
                bookTicketCryptoFeast();
            } else {
                toast.error("Please try later");
            }
        }
    };

    const bookTicket = () => {
        // handleSubmit();

        // handleSubmit &&
        axios
            .post(
                `${API_URL}canvas-perks`,
                // "http://192.168.18.25:4000/v1/users/adduser",
                {
                    email: inputData.email,
                    name: inputData.fullName,
                    phoneNumber: inputData.phone,
                    nftNumber: inputData.nftId,
                    // walletAddress: account,
                    shippingAddress: inputData.shippingAddress
                }
            )
            .then((res) => {
                window.$("#exampleModalconfrim").modal("hide");
                alert('Claim Successful. You will receive a confirmation email shortly.')

                // history.push(
                //   `/ticket?name=${inputData.fullName}&nft=${inputData.nftId}`
                // );
            })
            .catch((err) => {
                toast.error(err.message);
                window.$("#exampleModalconfrim").modal("hide");
                // alert("cannot send request | Bad Request Error");
                console.log("error", err);
                alert('NFT has already been used for claiming. Please select another NFT or wallet')
            });
    };

    const bookTicketWolf = () => {
        // handleSubmit();

        // handleSubmit &&
        axios
            .post(
                `${API_URL}/perk-wolves`,
                // "http://192.168.18.25:4000/v1/users/adduser",
                {
                    email: inputData.email,
                    name: inputData.fullName,
                    phoneNumber: inputData.phone,
                    nftNumber: inputData.nftId,
                    walletAddress: account,
                }
            )
            .then((res) => {
                window.$("#exampleModalconfrim").modal("hide");
                alert('Claim Successful. You will receive a confirmation email shortly.')

                // history.push(
                //   `/ticket?name=${inputData.fullName}&nft=${inputData.nftId}`
                // );
            })
            .catch((err) => {
                toast.error(err.message);
                window.$("#exampleModalconfrim").modal("hide");
                // alert("cannot send request | Bad Request Error");
                console.log("error", err);
                alert('NFT has already been used for claiming. Please select another NFT or wallet')
            });
    };

    const bookTicketLion = () => {
        // handleSubmit();

        // handleSubmit &&
        axios
            .post(
                `${API_URL}perk-lions`,
                // "http://192.168.18.25:4000/v1/users/adduser",
                {
                    email: inputData.email,
                    name: inputData.fullName,
                    phoneNumber: inputData.phone,
                    nftNumber: inputData.nftId,
                    walletAddress: account,
                }
            )
            .then((res) => {
                window.$("#exampleModalconfrim").modal("hide");
                alert('Claim Successful. You will receive a confirmation email shortly.')

                // history.push(
                //   `/ticket?name=${inputData.fullName}&nft=${inputData.nftId}`
                // );
            })
            .catch((err) => {
                toast.error(err.message);
                window.$("#exampleModalconfrim").modal("hide");
                // alert("cannot send request | Bad Request Error");
                console.log("error", err);
                alert('NFT has already been used for claiming. Please select another NFT or wallet')
            });
    };

    const bookTicketEarly = () => {
        // handleSubmit();

        // handleSubmit &&
        axios
            .post(
                `${API_URL}perk-lions`,
                // "http://192.168.18.25:4000/v1/users/adduser",
                {
                    email: inputData.email,
                    name: inputData.fullName,
                    phoneNumber: inputData.phone,
                    nftNumber: parseInt(inputData.nftId),
                    walletAddress: account,
                    type: "DrivenVerse Wolfpack Early Preview",
                    guest1: inputData.guest1,
                    guest2: inputData.guest2,
                    guest3: inputData.guest3,
                    guest4: inputData.guest4,
                    guest5: inputData.guest5,
                }
            )
            .then((res) => {
                window.$("#exampleModalconfrim").modal("hide");
                alert('Claim Successful. You will receive a confirmation email shortly.')

                // history.push(
                //   `/ticket?name=${inputData.fullName}&nft=${inputData.nftId}`
                // );
            })
            .catch((err) => {
                toast.error(err.message);
                window.$("#exampleModalconfrim").modal("hide");
                // alert("cannot send request | Bad Request Error");
                console.log("error", err);
                alert('NFT has already been used for claiming. Please select another NFT or wallet')
            });
    };

    const bookTicketLaunch = () => {
        // handleSubmit();

        // handleSubmit &&
        axios
            .post(
                `${API_URL}perk-lions`,
                // "http://192.168.18.25:4000/v1/users/adduser",
                {
                    email: inputData.email,
                    name: inputData.fullName,
                    phoneNumber: inputData.phone,
                    nftNumber: parseInt(inputData.nftId),
                    walletAddress: account,
                    type: "DrivenVerse Launch Party",
                    guest1: inputData.guest1,
                    guest2: inputData.guest2,
                    guest3: inputData.guest3,
                    guest4: inputData.guest4,
                    guest5: inputData.guest5,
                }
            )
            .then((res) => {
                window.$("#exampleModalconfrim").modal("hide");
                alert('Claim Successful. You will receive a confirmation email shortly.')

                // history.push(
                //   `/ticket?name=${inputData.fullName}&nft=${inputData.nftId}`
                // );
            })
            .catch((err) => {
                toast.error(err.message);
                window.$("#exampleModalconfrim").modal("hide");
                // alert("cannot send request | Bad Request Error");
                console.log("error", err);
                alert('NFT has already been used for claiming. Please select another NFT or wallet')
            });
    };

    const bookTicketDay = () => {
        // handleSubmit();

        // handleSubmit &&
        axios
            .post(
                `${API_URL}perk-lions`,
                // "http://192.168.18.25:4000/v1/users/adduser",
                {
                    email: inputData.email,
                    name: inputData.fullName,
                    phoneNumber: inputData.phone,
                    nftNumber: parseInt(inputData.nftId),
                    walletAddress: account,
                    type: "DrivenVerse Day Passes Unlocked",
                    guest1: inputData.guest1,
                    guest2: inputData.guest2,
                    guest3: inputData.guest3,
                    guest4: inputData.guest4,
                    guest5: inputData.guest5,
                }
            )
            .then((res) => {
                window.$("#exampleModalconfrim").modal("hide");
                alert('Claim Successful. You will receive a confirmation email shortly.')

                // history.push(
                //   `/ticket?name=${inputData.fullName}&nft=${inputData.nftId}`
                // );
            })
            .catch((err) => {
                toast.error(err.message);
                window.$("#exampleModalconfrim").modal("hide");
                // alert("cannot send request | Bad Request Error");
                console.log("error", err);
                alert('NFT has already been used for claiming. Please select another NFT or wallet')
            });
    };

    const bookTicketCryptoFeast = () => {
        // handleSubmit();

        // handleSubmit &&
        axios
            .post(
                `${API_URL}perk-feast`,
                {
                    email: inputData.email,
                    name: inputData.fullName,
                    phoneNumber: inputData.phone,
                    nft: inputData.nftId,
                    walletAddress: account,
                    guest1: inputData.guest1,
                    guest2: inputData.guest2,
                    guest3: inputData.guest3,
                    guest4: inputData.guest4,
                    guest5: inputData.guest5,
                    guest6: inputData.guest6,
                    guest7: inputData.guest7,
                    guest8: inputData.guest8,
                    guest9: inputData.guest9,
                    guest10: inputData.guest10
                }
            )
            .then((res) => {
                window.$("#exampleModalconfrim").modal("hide");
                alert('Claim Successful. You will receive a confirmation email shortly.')

                // history.push(
                //   `/ticket?name=${inputData.fullName}&nft=${inputData.nftId}`
                // );
            })
            .catch((err) => {
                toast.error(err.message);
                window.$("#exampleModalconfrim").modal("hide");
                // alert("cannot send request | Bad Request Error");
                console.log("error", err);
                alert('NFT has already been used for claiming. Please select another NFT or wallet')
            });
    };



    const openModal = () => {
        if (nftID.length > 0) {
            setInputData({
                fullName: "",
                email: "",
                phone: "",
                nftId: "",
                shippingAddress: "",
                guest1: "",
                guest2: "",
                guest3: "",
                guest4: "",
                guest5: "",
                guest1Error: "",
                guest2Error: "",
                guest3Error: "",
                guest4Error: "",
                guest5Error: "",
                fullNameError: "",
                emailError: "",
                nftIdError: "",
                shippingAddressError: ""
            })
            window.$("#exampleModalconfrim").modal("show");
        } else {
            alert('No NFT Found in your wallet. Please select another wallet')
        }
    }

    const openModalWolf = () => {
        if (nftID.length > 0) {
            setInputData({
                fullName: "",
                email: "",
                phone: "",
                nftId: "",
                shippingAddress: "",
                guest1: "",
                guest2: "",
                guest3: "",
                guest4: "",
                guest5: "",
                guest1Error: "",
                guest2Error: "",
                guest3Error: "",
                guest4Error: "",
                guest5Error: "",
                fullNameError: "",
                emailError: "",
                nftIdError: "",
                shippingAddressError: ""
            })
            window.$("#exampleModalconfrimWolfPack").modal("show");
        } else {
            alert('No NFT Found in your wallet. Please select another wallet')
        }
    }

    const openModalLion = () => {
        if (nftID.length > 0) {
            setInputData({
                fullName: "",
                email: "",
                phone: "",
                nftId: "",
                shippingAddress: "",
                guest1: "",
                guest2: "",
                guest3: "",
                guest4: "",
                guest5: "",
                guest1Error: "",
                guest2Error: "",
                guest3Error: "",
                guest4Error: "",
                guest5Error: "",
                fullNameError: "",
                emailError: "",
                nftIdError: "",
                shippingAddressError: ""
            })
            window.$("#exampleModalconfrimLionPack").modal("show");
        } else {
            alert('No NFT Found in your wallet. Please select another wallet')
        }
    }

    const openModalEarly = () => {
        if (nftID.length > 0) {
            setInputData({
                fullName: "",
                email: "",
                phone: "",
                nftId: "",
                shippingAddress: "",
                guest1: "",
                guest2: "",
                guest3: "",
                guest4: "",
                guest5: "",
                guest1Error: "",
                guest2Error: "",
                guest3Error: "",
                guest4Error: "",
                guest5Error: "",
                fullNameError: "",
                emailError: "",
                nftIdError: "",
                shippingAddressError: ""
            })
            window.$("#exampleModalconfrimEarly").modal("show");
        } else {
            alert('No NFT Found in your wallet. Please select another wallet')
        }
    }

    const openModalLaunch = () => {
        if (nftID.length > 0) {
            setInputData({
                fullName: "",
                email: "",
                phone: "",
                nftId: "",
                shippingAddress: "",
                guest1: "",
                guest2: "",
                guest3: "",
                guest4: "",
                guest5: "",
                guest1Error: "",
                guest2Error: "",
                guest3Error: "",
                guest4Error: "",
                guest5Error: "",
                fullNameError: "",
                emailError: "",
                nftIdError: "",
                shippingAddressError: ""
            })
            window.$("#exampleModalconfrimLaunch").modal("show");
        } else {
            alert('No NFT Found in your wallet. Please select another wallet')
        }
    }

    const openModalDay = () => {
        if (nftID.length > 0) {
            setInputData({
                fullName: "",
                email: "",
                phone: "",
                nftId: "",
                shippingAddress: "",
                guest1: "",
                guest2: "",
                guest3: "",
                guest4: "",
                guest5: "",
                guest1Error: "",
                guest2Error: "",
                guest3Error: "",
                guest4Error: "",
                guest5Error: "",
                fullNameError: "",
                emailError: "",
                nftIdError: "",
                shippingAddressError: ""
            })
            window.$("#exampleModalconfrimLionDay").modal("show");
        } else {
            alert('No NFT Found in your wallet. Please select another wallet')
        }
    }

    const openCryptoFest = () => {
        if (nftID?.length > 0) {
            setInputData({
                fullName: "",
                email: "",
                phone: "",
                nftId: "",
                shippingAddress: "",
                guest1: "",
                guest2: "",
                guest3: "",
                guest4: "",
                guest5: "",
                guest6: "",
                guest7: "",
                guest8: "",
                guest9: "",
                guest10: "",
                guest1Error: "",
                guest2Error: "",
                guest3Error: "",
                guest4Error: "",
                guest5Error: "",
                guest6Error: "",
                guest7Error: "",
                guest8Error: "",
                guest9Error: "",
                guest10Error: "",
                fullNameError: "",
                emailError: "",
                nftIdError: "",
                shippingAddressError: ""
            })
            window.$("#exampleModalconfrimCryptoFest").modal("show");
        } else {
            alert('No NFT Found in your wallet. Please select another wallet')
        }
    }


    useEffect(() => {
        if (account) {
            data();

        }
    }, [account]);
    return (
        <>
            <div className="merch-main nftperks-main">
                <Navbar />
                <div className="container-fluid pb-5">
                    <div className="row">
                        <div className="col-sm-12 p-0 mx-auto">
                            <div className="heading-div text-center mt-5">
                                <h2>Driven NFT <span className='common'>Holder Perks </span> </h2> </div>
                            <div className="row ptb my-5 ">
                                <div className="col-lg-1">
                                </div>
                                <div className="col-lg-2  col-md-6  pt-lg-0 pt-5">
                                    <div className="cardo mb-md-0 mb-5">
                                        <div className="img-container-div">
                                            <img src="merch/car.jpg" alt="metamasklogo" className='img-fluid' />
                                        </div>
                                        <div className="text-center mt-4">
                                            <h4 className='font-weight-bold text-white text-uppercase mb-2'><span className='common'>720S NFT Wrapped McLaren</span> </h4>
                                            {/* <button className='btn-common-2 mt-5 text-uppercase w-75' data-toggle="modal" data-target="#exampleModalconfrim">Claim</button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6  pt-lg-0 pt-5">
                                    <div className="cardo mb-md-0 mb-5">
                                        <div className="img-container-div">
                                            <img src="merch/rolex.jpg" alt="metamasklogo" className='img-fluid' />
                                        </div>
                                        <div className="text-center mt-4">
                                            <h4 className='font-weight-bold text-white text-uppercase mb-2'><span className='common'>Rolex Watch</span> </h4>
                                            {/* <button className='btn-common-2 mt-5 text-uppercase w-75' data-toggle="modal" data-target="#exampleModalconfrim">Claim</button> */}

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6  pt-lg-0 pt-5">
                                    <div className="cardo mb-md-0 mb-5">
                                        <div className="img-container-div">
                                            <img src="merch/canvasnft.png" alt="metamasklogo" className='img-fluid' />
                                        </div>
                                        <div className="text-center mt-4">
                                            <h4 className='font-weight-bold text-white text-uppercase mb-2'><span className='common'>NFT Canvas</span> </h4>
                                            {/* <button className='btn-common-2 mt-5 text-uppercase w-75' onClick={openModal}>Claim</button> */}
                                            <button className='btn-common-23 mt-5 text-uppercase w-75' >Sold Out</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6  pt-lg-0 pt-5">
                                    <div className="cardo mb-md-0 mb-5">
                                        <div className="img-container-div">
                                            <img src="merch/lion.jpeg" alt="metamasklogo" className='img-fluid' />
                                        </div>
                                        <div className="text-center mt-4">
                                            <h4 className='font-weight-bold text-white text-uppercase mb-2'><span className='common'>Driven Minneapolis Experience (Lions)</span> </h4>
                                            {/* <button className='btn-common-2 mt-5 text-uppercase w-75' onClick={openModalLion}>Claim</button> */}
                                            <button className='btn-common-23 mt-5 text-uppercase w-75' >Sold Out</button>

                                            {/* <button className='btn-common-2 mt-5 text-uppercase w-75' data-toggle="modal" data-target="#exampleModalconfrim">Claim</button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 pt-lg-0 pt-5">
                                    <div className="cardo mb-md-0 mb-5">
                                        <div className="img-container-div">
                                            <img src="merch/wolf.jpeg" alt="metamasklogo" className='img-fluid' />
                                        </div>
                                        <div className="text-center mt-4">
                                            <h4 className='font-weight-bold text-white text-uppercase mb-2'><span className='common'>Driven Minneapolis Experience (Wolves)</span> </h4>
                                            {/* <button className='btn-common-2 mt-5 text-uppercase w-75' onClick={openModalWolf}>Claim</button> */}
                                            <button className='btn-common-23 mt-5 text-uppercase w-75' >Sold Out</button>

                                            {/* <button className='btn-common-2 mt-5 text-uppercase w-75' data-toggle="modal" data-target="#exampleModalconfrim">Claim</button> */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 p-0 mx-auto">
                            <div className="row my-5 ">
                                {/* <div className="col-lg-1">
                                </div> */}
                                <div className='col-lg-2 col-md-6  pt-lg-0 pt-5'></div>
                                <div className="col-lg-2 col-md-6  pt-lg-0 pt-5">
                                    <div className="cardo mb-md-0 mb-5">
                                        <div className="img-container-div">
                                            <img src="merch/early.jpeg" alt="metamasklogo" className='img-fluid' />
                                        </div>
                                        <div className="text-center mt-4">
                                            <h4 className='font-weight-bold text-white text-uppercase mb-2'><span className='common'>DrivenVerse Wolfpack Early Preview <br /> <span>(Wolves & above) </span></span> </h4>
                                            {/* <button className='btn-common-2 mt-5 text-uppercase w-75' onClick={openModalEarly}>Claim</button> */}
                                            <button className='btn-common-23 mt-5 text-uppercase w-75' >Sold Out</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6  pt-lg-0 pt-5">
                                    <div className="cardo mb-md-0 mb-5">
                                        <div className="img-container-div">
                                            <img src="merch/launchparty.jpeg" alt="metamasklogo" className='img-fluid' />
                                        </div>
                                        <div className="text-center mt-4">
                                            <h4 className='font-weight-bold text-white text-uppercase mb-2'><span className='common'>DrivenVerse Launch Party</span> </h4>
                                            {/* <button className='btn-common-2 mt-5 text-uppercase w-75' onClick={openModalLaunch}>Claim</button> */}
                                            <button className='btn-common-23 mt-5 text-uppercase w-75' >Sold Out</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6  pt-lg-0 pt-5">
                                    <div className="cardo mb-md-0 mb-5">
                                        <div className="img-container-div">
                                            <img src="merch/daypasses.jpeg" alt="metamasklogo" className='img-fluid' />
                                        </div>
                                        <div className="text-center mt-4">
                                            <h4 className='font-weight-bold text-white text-uppercase mb-2'><span className='common'>DrivenVerse Day Passes Unlocked <br /> <span>(ALL NFT HOLDERS) </span></span> </h4>
                                            {/* <button className='btn-common-2 mt-5 text-uppercase w-75' onClick={openModalDay}>Claim</button> */}
                                            <button className='btn-common-23 mt-5 text-uppercase w-75' >Sold Out</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6  pt-lg-0 pt-5">
                                    <div className="cardo mb-md-0 mb-5">
                                        <div className="img-container-div">
                                            <img src="merch/drivenVerse.jpeg" alt="metamasklogo" className='img-fluid' />
                                        </div>
                                        <div className="text-center mt-4">
                                            <h4 className='font-weight-bold text-white text-uppercase mb-2'><span className='common'>Crypto Fest </span> </h4>
                                            <button className='btn-common-2 mt-5 text-uppercase w-75' onClick={openCryptoFest}>Claim</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-2 col-md-6  pt-lg-0 pt-5'></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalconfrim">
                Launch demo modal
            </button> */}

            <>
                {/* //buy now modal */}
                <div class="modal fade" id="exampleModalconfrim" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            {/* <div class="modal-header">
                                <h4 class="modal-title text-white text-center text-uppercase w-100" id="exampleModalLabel">Connect <span className='common'>Wallet</span> </h4>
                                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"><img src="assets/errorSign.svg" alt="" /></button>
                            </div> */}
                            <div class="modal-body p-sm-4 p-4 text-center">
                                <div className="row pb-2 pt-1">

                                    <div className="col-md-12 my-auto p-0 text-left">
                                        <h4 className='font-weight-bold text-white mb-3 ml-3'>Contact <span className='common'>Information</span> </h4>
                                        <div className="row my-4">
                                            <div className="input-pack-container ">
                                                <div className="row">
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">full name</label>
                                                        <input
                                                            name="fullName"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.fullName}
                                                            type="text"
                                                            placeholder="Enter your full name"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.fullNameError}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            email address
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.email}
                                                            placeholder="Enter your email address"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.emailError}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">Phone Number</label>
                                                        <input
                                                            type="number"
                                                            name="phone"
                                                            onChange={handleInputChange}
                                                            required
                                                            min={1}
                                                            value={inputData.phone}
                                                            placeholder="Enter Phone Number"
                                                        />
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">Shipping Address</label>
                                                        <input
                                                            name="shippingAddress"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.shippingAddress}
                                                            type="text"
                                                            placeholder="Enter your Address"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.shippingAddressError}
                                                        </p>
                                                    </div>
                                                    {/* <div className="col-md-6 d-flex flex-column">
                                                        <label for="exampleInputEmail1">shipping address</label>
                                                        <input type="text" placeholder='Enter shipping address' />
                                                    </div> */}
                                                    <div className="col-md-12 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">NFT ID</label>
                                                        <select
                                                            type="number"
                                                            name="nftId"
                                                            onChange={handleInputChange}
                                                            placeholder="Enter NFT ID"
                                                            required
                                                            id="nftId"
                                                        // value={inputData.nftId}
                                                        >
                                                            <option disabled selected>
                                                                Select NFT number
                                                            </option>
                                                            {nftID?.map((nft, index) => {
                                                                return (
                                                                    <option
                                                                        key={index}
                                                                        value={parseInt(nft.token_id)}
                                                                    >
                                                                        {nft.token_id}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                        <p className="text-danger p-2">
                                                            {inputData.nftIdError}
                                                        </p>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button className='btn-common-2  w-50  ' onClick={handleSubmit}>Confirm</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModalconfrimWolfPack" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            {/* <div class="modal-header">
                                <h4 class="modal-title text-white text-center text-uppercase w-100" id="exampleModalLabel">Connect <span className='common'>Wallet</span> </h4>
                                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"><img src="assets/errorSign.svg" alt="" /></button>
                            </div> */}
                            <div class="modal-body p-sm-4 p-4 text-center">
                                <div className="row pb-2 pt-1">

                                    <div className="col-md-12 my-auto p-0 text-left">
                                        <h4 className='font-weight-bold text-white mb-3 ml-3'>Contact <span className='common'>Information</span> </h4>
                                        <div className="row my-4">
                                            <div className="input-pack-container ">
                                                <div className="row">
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">full name</label>
                                                        <input
                                                            name="fullName"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.fullName}
                                                            type="text"
                                                            placeholder="Enter your full name"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.fullNameError}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            email address
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.email}
                                                            placeholder="Enter your email address"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.emailError}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">Phone Number</label>
                                                        <input
                                                            type="number"
                                                            name="phone"
                                                            onChange={handleInputChange}
                                                            required
                                                            min={1}
                                                            value={inputData.phone}
                                                            placeholder="Enter Phone Number"
                                                        />
                                                    </div>
                                                    {/* <div className="col-md-6 d-flex flex-column">
                                                        <label for="exampleInputEmail1">shipping address</label>
                                                        <input type="text" placeholder='Enter shipping address' />
                                                    </div> */}
                                                    <div className="col-md-12 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">NFT ID</label>
                                                        <select
                                                            type="number"
                                                            name="nftId"
                                                            onChange={handleInputChange}
                                                            placeholder="Enter NFT ID"
                                                            required
                                                            id="nftId"
                                                        // value={inputData.nftId}
                                                        >
                                                            <option disabled selected>
                                                                Select NFT number
                                                            </option>
                                                            {nftID?.map((nft, index) => {
                                                                return (
                                                                    <option
                                                                        key={index}
                                                                        value={parseInt(nft.token_id)}
                                                                    >
                                                                        {nft.token_id}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                        <p className="text-danger p-2">
                                                            {inputData.nftIdError}
                                                        </p>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button className='btn-common-2  w-50  ' onClick={handleSubmitWolf}>Confirm</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModalconfrimLionPack" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            {/* <div class="modal-header">
                                <h4 class="modal-title text-white text-center text-uppercase w-100" id="exampleModalLabel">Connect <span className='common'>Wallet</span> </h4>
                                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"><img src="assets/errorSign.svg" alt="" /></button>
                            </div> */}
                            <div class="modal-body p-sm-4 p-4 text-center">
                                <div className="row pb-2 pt-1">

                                    <div className="col-md-12 my-auto p-0 text-left">
                                        <h4 className='font-weight-bold text-white mb-3 ml-3'>Contact <span className='common'>Information</span> </h4>
                                        <div className="row my-4">
                                            <div className="input-pack-container ">
                                                <div className="row">
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">full name</label>
                                                        <input
                                                            name="fullName"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.fullName}
                                                            type="text"
                                                            placeholder="Enter your full name"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.fullNameError}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            email address
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.email}
                                                            placeholder="Enter your email address"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.emailError}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">Phone Number</label>
                                                        <input
                                                            type="number"
                                                            name="phone"
                                                            onChange={handleInputChange}
                                                            required
                                                            min={1}
                                                            value={inputData.phone}
                                                            placeholder="Enter Phone Number"
                                                        />
                                                    </div>
                                                    {/* <div className="col-md-6 d-flex flex-column">
                                                        <label for="exampleInputEmail1">shipping address</label>
                                                        <input type="text" placeholder='Enter shipping address' />
                                                    </div> */}
                                                    <div className="col-md-12 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">NFT ID</label>
                                                        <select
                                                            type="number"
                                                            name="nftId"
                                                            onChange={handleInputChange}
                                                            placeholder="Enter NFT ID"
                                                            required
                                                            id="nftId"
                                                        // value={inputData.nftId}
                                                        >
                                                            <option disabled selected>
                                                                Select NFT number
                                                            </option>
                                                            {nftID?.map((nft, index) => {
                                                                return (
                                                                    <option
                                                                        key={index}
                                                                        value={parseInt(nft.token_id)}
                                                                    >
                                                                        {nft.token_id}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                        <p className="text-danger p-2">
                                                            {inputData.nftIdError}
                                                        </p>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button className='btn-common-2  w-50  ' onClick={handleSubmitLion}>Confirm</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModalconfrimEarly" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            {/* <div class="modal-header">
                                <h4 class="modal-title text-white text-center text-uppercase w-100" id="exampleModalLabel">Connect <span className='common'>Wallet</span> </h4>
                                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"><img src="assets/errorSign.svg" alt="" /></button>
                            </div> */}
                            <div class="modal-body p-sm-4 p-4 text-center">
                                <div className="row pb-2 pt-1">

                                    <div className="col-md-12 my-auto p-0 text-left">
                                        <h4 className='font-weight-bold text-white mb-3 ml-3'>Contact <span className='common'>Information</span> </h4>
                                        <div className="row my-4">
                                            <div className="input-pack-container ">
                                                <div className="row">
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">full name</label>
                                                        <input
                                                            name="fullName"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.fullName}
                                                            type="text"
                                                            placeholder="Enter your full name"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.fullNameError}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            email address
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.email}
                                                            placeholder="Enter your email address"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.emailError}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 1
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest1"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest1}
                                                            placeholder="Enter your Guest 1 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest1Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 2
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest2"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest2}
                                                            placeholder="Enter your Guest 2 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest2Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 3
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest3"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest3}
                                                            placeholder="Enter your Guest 3 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest3Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 4
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest4"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest4}
                                                            placeholder="Enter your Guest 4 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest4Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 5
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest5"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest5}
                                                            placeholder="Enter your Guest 5 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest5Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">Phone Number</label>
                                                        <input
                                                            type="number"
                                                            name="phone"
                                                            onChange={handleInputChange}
                                                            required
                                                            min={1}
                                                            value={inputData.phone}
                                                            placeholder="Enter Phone Number"
                                                        />
                                                    </div>
                                                    {/* <div className="col-md-6 d-flex flex-column">
                                                        <label for="exampleInputEmail1">shipping address</label>
                                                        <input type="text" placeholder='Enter shipping address' />
                                                    </div> */}
                                                    <div className="col-md-12 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">NFT ID</label>
                                                        <select
                                                            type="number"
                                                            name="nftId"
                                                            onChange={handleInputChange}
                                                            placeholder="Enter NFT ID"
                                                            required
                                                            id="nftId"
                                                        // value={inputData.nftId}
                                                        >
                                                            <option disabled selected>
                                                                Select NFT number
                                                            </option>
                                                            {nftID?.map((nft, index) => {
                                                                return (
                                                                    <option
                                                                        key={index}
                                                                        value={parseInt(nft.token_id)}
                                                                    >
                                                                        {nft.token_id}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                        <p className="text-danger p-2">
                                                            {inputData.nftIdError}
                                                        </p>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button className='btn-common-2  w-50  ' onClick={handleSubmitEarly}>Confirm</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModalconfrimLaunch" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            {/* <div class="modal-header">
                                <h4 class="modal-title text-white text-center text-uppercase w-100" id="exampleModalLabel">Connect <span className='common'>Wallet</span> </h4>
                                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"><img src="assets/errorSign.svg" alt="" /></button>
                            </div> */}
                            <div class="modal-body p-sm-4 p-4 text-center">
                                <div className="row pb-2 pt-1">

                                    <div className="col-md-12 my-auto p-0 text-left">
                                        <h4 className='font-weight-bold text-white mb-3 ml-3'>Contact <span className='common'>Information</span> </h4>
                                        <div className="row my-4">
                                            <div className="input-pack-container ">
                                                <div className="row">
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">full name</label>
                                                        <input
                                                            name="fullName"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.fullName}
                                                            type="text"
                                                            placeholder="Enter your full name"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.fullNameError}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            email address
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.email}
                                                            placeholder="Enter your email address"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.emailError}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 1
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest1"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest1}
                                                            placeholder="Enter your Guest 1 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest1Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 2
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest2"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest2}
                                                            placeholder="Enter your Guest 2 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest2Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 3
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest3"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest3}
                                                            placeholder="Enter your Guest 3 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest3Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 4
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest4"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest4}
                                                            placeholder="Enter your Guest 4 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest4Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 5
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest5"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest5}
                                                            placeholder="Enter your Guest 5 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest5Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">Phone Number</label>
                                                        <input
                                                            type="number"
                                                            name="phone"
                                                            onChange={handleInputChange}
                                                            required
                                                            min={1}
                                                            value={inputData.phone}
                                                            placeholder="Enter Phone Number"
                                                        />
                                                    </div>
                                                    {/* <div className="col-md-6 d-flex flex-column">
                                                        <label for="exampleInputEmail1">shipping address</label>
                                                        <input type="text" placeholder='Enter shipping address' />
                                                    </div> */}
                                                    <div className="col-md-12 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">NFT ID</label>
                                                        <select
                                                            type="number"
                                                            name="nftId"
                                                            onChange={handleInputChange}
                                                            placeholder="Enter NFT ID"
                                                            required
                                                            id="nftId"
                                                        // value={inputData.nftId}
                                                        >
                                                            <option disabled selected>
                                                                Select NFT number
                                                            </option>
                                                            {nftID?.map((nft, index) => {
                                                                return (
                                                                    <option
                                                                        key={index}
                                                                        value={parseInt(nft.token_id)}
                                                                    >
                                                                        {nft.token_id}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                        <p className="text-danger p-2">
                                                            {inputData.nftIdError}
                                                        </p>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button className='btn-common-2  w-50  ' onClick={handleSubmitLaunch}>Confirm</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModalconfrimLionDay" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            {/* <div class="modal-header">
                                <h4 class="modal-title text-white text-center text-uppercase w-100" id="exampleModalLabel">Connect <span className='common'>Wallet</span> </h4>
                                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"><img src="assets/errorSign.svg" alt="" /></button>
                            </div> */}
                            <div class="modal-body p-sm-4 p-4 text-center">
                                <div className="row pb-2 pt-1">

                                    <div className="col-md-12 my-auto p-0 text-left">
                                        <h4 className='font-weight-bold text-white mb-3 ml-3'>Contact <span className='common'>Information</span> </h4>
                                        <div className="row my-4">
                                            <div className="input-pack-container ">
                                                <div className="row">
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">full name</label>
                                                        <input
                                                            name="fullName"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.fullName}
                                                            type="text"
                                                            placeholder="Enter your full name"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.fullNameError}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            email address
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.email}
                                                            placeholder="Enter your email address"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.emailError}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 1
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest1"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest1}
                                                            placeholder="Enter your Guest 1 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest1Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 2
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest2"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest2}
                                                            placeholder="Enter your Guest 2 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest2Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 3
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest3"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest3}
                                                            placeholder="Enter your Guest 3 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest3Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 4
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest4"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest4}
                                                            placeholder="Enter your Guest 4 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest4Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 5
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest5"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest5}
                                                            placeholder="Enter your Guest 5 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest5Error}
                                                        </p>
                                                    </div>

                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">Phone Number</label>
                                                        <input
                                                            type="number"
                                                            name="phone"
                                                            onChange={handleInputChange}
                                                            required
                                                            min={1}
                                                            value={inputData.phone}
                                                            placeholder="Enter Phone Number"
                                                        />
                                                    </div>
                                                    {/* <div className="col-md-6 d-flex flex-column">
                                                        <label for="exampleInputEmail1">shipping address</label>
                                                        <input type="text" placeholder='Enter shipping address' />
                                                    </div> */}
                                                    <div className="col-md-12 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">NFT ID</label>
                                                        <select
                                                            type="number"
                                                            name="nftId"
                                                            onChange={handleInputChange}
                                                            placeholder="Enter NFT ID"
                                                            required
                                                            id="nftId"
                                                        // value={inputData.nftId}
                                                        >
                                                            <option disabled selected>
                                                                Select NFT number
                                                            </option>
                                                            {nftID?.map((nft, index) => {
                                                                return (
                                                                    <option
                                                                        key={index}
                                                                        value={parseInt(nft.token_id)}
                                                                    >
                                                                        {nft.token_id}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                        <p className="text-danger p-2">
                                                            {inputData.nftIdError}
                                                        </p>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button className='btn-common-2  w-50  ' onClick={handleSubmitDay}>Confirm</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModalconfrimCryptoFest" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            {/* <div class="modal-header">
                                <h4 class="modal-title text-white text-center text-uppercase w-100" id="exampleModalLabel">Connect <span className='common'>Wallet</span> </h4>
                                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"><img src="assets/errorSign.svg" alt="" /></button>
                            </div> */}
                            <div class="modal-body p-sm-4 p-4 text-center">
                                <div className="row pb-2 pt-1">

                                    <div className="col-md-12 my-auto p-0 text-left">
                                        <h4 className='font-weight-bold text-white mb-3 ml-3'>Contact <span className='common'>Information</span> </h4>
                                        <div className="row my-4">
                                            <div className="input-pack-container ">
                                                <div className="row">
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">full name</label>
                                                        <input
                                                            name="fullName"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.fullName}
                                                            type="text"
                                                            placeholder="Enter your full name"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.fullNameError}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            email address
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.email}
                                                            placeholder="Enter your email address"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.emailError}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 1
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest1"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest1}
                                                            placeholder="Enter your Guest 1 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest1Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 2
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest2"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest2}
                                                            placeholder="Enter your Guest 2 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest2Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 3
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest3"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest3}
                                                            placeholder="Enter your Guest 3 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest3Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 4
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest4"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest4}
                                                            placeholder="Enter your Guest 4 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest4Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 5
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest5"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest5}
                                                            placeholder="Enter your Guest 5 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest5Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 6
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest6"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest6}
                                                            placeholder="Enter your Guest 6 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest6Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 7
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest7"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest7}
                                                            placeholder="Enter your Guest 7 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest7Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 8
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest8"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest8}
                                                            placeholder="Enter your Guest 8 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest8Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 9
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest4"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest9}
                                                            placeholder="Enter your Guest 9 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest9Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Guest 10
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="guest10"
                                                            onChange={handleInputChange}
                                                            required
                                                            value={inputData.guest10}
                                                            placeholder="Enter your Guest 10 Email"
                                                        />
                                                        <p className="text-danger p-2">
                                                            {inputData.guest10Error}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">Phone Number</label>
                                                        <input
                                                            type="number"
                                                            name="phone"
                                                            onChange={handleInputChange}
                                                            required
                                                            min={1}
                                                            value={inputData.phone}
                                                            placeholder="Enter Phone Number"
                                                        />
                                                    </div>
                                                    {/* <div className="col-md-6 d-flex flex-column">
                                                        <label for="exampleInputEmail1">shipping address</label>
                                                        <input type="text" placeholder='Enter shipping address' />
                                                    </div> */}
                                                    <div className="col-md-12 d-flex flex-column">
                                                        <label htmlFor="exampleInputEmail1">NFT ID</label>
                                                        <select
                                                            type="number"
                                                            name="nftId"
                                                            onChange={handleInputChange}
                                                            placeholder="Enter NFT ID"
                                                            required
                                                            id="nftId"
                                                        // value={inputData.nftId}
                                                        >
                                                            <option disabled selected>
                                                                Select NFT number
                                                            </option>
                                                            {nftID?.map((nft, index) => {
                                                                return (
                                                                    <option
                                                                        key={index}
                                                                        value={nft.symbol + " # " + nft.token_id}
                                                                    >
                                                                        {nft.symbol + " # " + nft.token_id}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                        <p className="text-danger p-2">
                                                            {inputData.nftIdError}
                                                        </p>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button className='btn-common-2  w-50  ' onClick={handleSubmitCryptoFest}>Confirm</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <div className="fotter-hide-top">
                <Footer />
            </div>
        </>
    )
}

export default NftPerks


